<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    resultCount: number
    filterCount: number
    showEmpty?: boolean
    onlyFiltered?: boolean
    canClear?: boolean
  }>(),
  {
    resultCount: 0,
    filterCount: 0,
    showEmpty: false,
    onlyFiltered: false,
    canClear: true,
  },
)

const emit = defineEmits<{
  clear: [event: Event]
}>()

defineSlots<{
  default: { count: number }
  filter: { count: number }
}>()

const hasResults = computed(() => props.resultCount > 0)
const hasFilters = computed(() => props.filterCount > 0)
const filterText = computed(() =>
  props.filterCount === 1 ? 'filter' : 'filters',
)
const isVisible = computed(() => {
  const results = hasResults.value || props.showEmpty
  const filters = hasFilters.value || !props.onlyFiltered
  return results && filters
})
</script>
<template>
  <span v-if="isVisible" class="flex items-center gap-2">
    <!-- FILTERED RESULTS -->
    <span v-if="hasFilters" class="text-sm font-medium">
      <slot :count="resultCount">Showing {{ resultCount }} results</slot> with
      <Tooltip
        content="Filters may reduce the number of results"
        class="bg-gray-950 text-xs"
        placement="top"
      >
        <span class="underline decoration-dotted">
          <slot name="filter" :count="filterCount">
            {{ filterCount }} {{ filterText }} applied
          </slot>
        </span>
      </Tooltip>
    </span>

    <!-- ALL RESULTS -->
    <span v-else class="text-sm font-medium">
      <slot :count="resultCount">Showing {{ resultCount }} results</slot>
    </span>

    <!-- CLEAR FILTERS -->
    <Button
      v-if="hasFilters && canClear"
      color="opacityPrimary"
      size="sm"
      class="flex justify-center self-end whitespace-nowrap px-3 py-2 !text-xs"
      @click="emit('clear', $event)"
    >
      Clear filters
    </Button>
  </span>
</template>
